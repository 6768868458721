<template>
  <div id="educator-info">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <template v-if="educator">
            <b-tabs v-model="activeTab">
              <b-tab-item label="Basic">
                <div class="columns is-multiline">
                  <div class="column is-3 primary">Name</div>
                  <div class="column is-9 text-bg-color">
                    {{ educator.name }}
                  </div>
                  <div class="column is-3 primary">Date Of Birth</div>
                  <div class="column is-9 text-bg-color">
                    {{ educator.dateOfBirth }}
                  </div>
                  <div class="column is-3 primary">Gender</div>
                  <div class="column is-9 text-bg-color">
                    {{ educator.gender }}
                  </div>
                  <div class="column is-3 primary">Religion</div>
                  <div class="column is-9 text-bg-color">
                    {{ educator.religion }}
                  </div>
                  <div class="column is-3 primary">Email</div>
                  <div class="column is-9 text-bg-color">
                    {{ educator.email }}
                  </div>
                  <div class="column is-3 primary">Signature</div>
                  <div class="column is-9 text-bg-color">
                    <img
                      v-if="educator.signature"
                      :src="educator.signature"
                      alt="signature"
                      width="128"
                      height="auto"
                    />
                    <img
                      v-else
                      src="@/assets/images/default_signature.png"
                      alt="signature"
                      width="128"
                      height="auto"
                    />
                  </div>
                </div>
                <div class="buttons is-pulled-right">
                  <a
                    class="is-small has-text-success"
                    @click="initializeUpdate()"
                    style="font-size: 1.2em; margin-left: 16px"
                  >
                    <fa-icon icon="edit" size="lg" />
                  </a>
                  <a
                    class="is-small has-text-danger"
                    @click="initializeRemove()"
                    style="font-size: 1.2em; margin-left: 16px"
                  >
                    <fa-icon icon="trash-alt" size="lg" />
                  </a>
                </div>
              </b-tab-item>
              <b-tab-item label="Assigned Subjects">
                <ol>
                  <li
                    v-for="item in this.assignedSubjects"
                    :key="item"
                    class="subtitle is-6"
                  >
                    {{ item }}
                  </li>
                </ol>
              </b-tab-item>
              <b-tab-item label="Online">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <div class="field">
                      <b-switch v-model="userAuth" type="is-success"
                        >Educator Auth</b-switch
                      >
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="Signature">
                <div class="has-text-centered">
                  <figure class="image is-256x256 is-inline-block">
                    <img v-if="educator.signature" :src="educator.signature" />
                    <img v-else src="@/assets/images/default_signature.png" />
                  </figure>
                </div>
                <br />
                <div class="has-text-centered">
                  <a
                    class="button btn-120 is-primary"
                    @click="uploadImage('signature')"
                    >Change</a
                  >
                </div>
                <ImageModal
                  :subject="imageSubject"
                  :data="
                    educator.signature
                      ? { id: educator.id, url: educator.signature }
                      : {}
                  "
                  :is-data-url="true"
                  :show-modal.sync="modal"
                  @create-upload="createUpload"
                  @update-upload="updateUpload"
                />
              </b-tab-item>
            </b-tabs>
          </template>
          <template v-else>No Educator</template>
        </div>
      </div>
    </template>
    <app-modal
      id="activate-reset-admin"
      :context="context"
      @remove="remove"
      @activate="activate"
      @reset="reset"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchAppUrl, fetchUser } from '@/assets/js/app_info.js'
import { crudNotification } from '@/assets/js/notification'
import ImageModal from '../../components/ImageModal.vue'

export default {
  data() {
    return {
      url: null,
      schoolId: null,
      pageTitle: 'Educator Info',
      educator: {
        id: null,
        user: {
          firebaseUserId: '',
        },
      },
      excludedKeys: [
        'id',
        'users',
        '__typename',
        'parentId',
        'imageUrl',
        'user_id',
        'user',
      ],
      activeTab: 0,
      action: null,
      context: null,
      modal: false,
      imageSubject: 'avatar',
      assignedSubjects: [],
    }
  },
  watch: {
    educator(data) {
      this.assignedSubjects = Array.from(
        new Set(
          data.assignedSubjects.map(
            (assignedSubject) => assignedSubject.schoolClass.name
          )
        )
      )
      this.selectedUser = data.user
      this.userAuth = this.isActivated()
    },
    isModalOpen() {
      this.$apollo.queries.educator.refetch()
      this.userAuth = this.isActivated()
    },
  },
  methods: {
    // For image modal
    uploadImage(value) {
      this.imageSubject = value
      this.modal = true
    },
    createUpload(data) {
      if (data.subject === 'signature') {
        this.updateEducatorSignature(data.dataUrl)
      }
    },
    updateUpload(data) {
      if (data.subject === 'signature') {
        this.updateEducatorSignature(data.dataUrl)
      }
    },
    updateEducatorSignature(signatureData) {
      // Update Signature
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateEducatorSignatureQuery(
              $id: ID!
              $signature: String!
            ) {
              updateEducatorSignature(
                input: { id: $id, signature: $signature }
              ) {
                educator {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            id: this.educator.id,
            signature: signatureData,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateEducatorSignature.errors,
            'Successfully updated.'
          ).then(() => {
            this.educator.signature = signatureData
            this.modal = false
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    initializeUpdate() {
      this.$router.push(`/school/${this.schoolId}/educator/${this.educator.id}`)
    },
    initializeRemove() {
      this.context = 'remove'
      this.openModal()
    },
    itemValue(value) {
      if (!value) return
      if (typeof value === 'object') {
        const { name } = value
        value = name
      }
      return value
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteEducator($id: Int!) {
              deleteEducator(input: { id: $id }) {
                educator {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.educator.id),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteEducator.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.reload()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  components: {
    ImageModal,
    // AuthUI: () => import('@/components/AuthUI'),
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)

    fetchAppUrl().then((url) => {
      this.url = url
    })

    fetchUser().then((user) => {
      // this.user = user
      this.schoolId = user.school_id
      this.$store.commit('setSubMenus', [
        {
          name: 'Educators',
          route: `/school/${this.$route.params.school_id}/educators`,
        },
      ])

      this.$apollo.addSmartQuery('educator', {
        query: gql`
          query educator($id: ID!) {
            educator(id: $id) {
              id
              imageUrl
              name
              dateOfBirth
              gender
              religion
              email
              ownerId
              user {
                id
                email
                firebaseUserId
              }
              signature
              assignedSubjects {
                schoolClass {
                  name
                }
              }
            }
          }
        `,
        variables: {
          id: this.$route.params.id,
        },
        error(error) {
          this.$buefy.notification.open({
            duration: 5000,
            message: error.message,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
          })
        },
      })
      this.$apollo.queries.educator.refetch()
    })
  },
}
</script>
